import React, { useEffect, useRef, useState } from 'react'
import styles from '../styles/AssetsList.module.scss'
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { colourStylesCurrency } from './SelectCurrencyStyles';
import secureLocalStorage from 'react-secure-storage';
import altIcon from '../imgs/altIcon.svg';

export default function AssetsList({ favorites, setActiveAsset, activeAsset, toggleFavorite, currentRecords, activePrice, setActivePrice, theme, account, eurToUsd, usdToEur, handleClick, setSnackMessage, setSnackType }) {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState('')
  const [selectAssets, setSelectAssets] = useState({ value: '999', label: t('AllAssets') })
  const [currentAssets, setCurrentAssets] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const inputRef = useRef(null); 

  const handleSearchClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  const handleImgError = (e) => {
    e.target.src = altIcon;
  };
  const [displayedRecords, setDisplayedRecords] = useState([]);
  const [selectOptions, setSelectedOptions] = useState([
    { value: '999', label: t('AllAssets'), icon: "" },
    { value: '1', label: t('CryptoCurrencies'), icon: 
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M10 18.3333C14.6024 18.3333 18.3334 14.6023 18.3334 9.99996C18.3334 5.39759 14.6024 1.66663 10 1.66663C5.39765 1.66663 1.66669 5.39759 1.66669 9.99996C1.66669 14.6023 5.39765 18.3333 10 18.3333Z" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.33335 6.66667V10H11.25C11.692 10 12.116 9.8244 12.4285 9.51184C12.7411 9.19928 12.9167 8.77536 12.9167 8.33333C12.9167 7.89131 12.7411 7.46738 12.4285 7.15482C12.116 6.84226 11.692 6.66667 11.25 6.66667H10.4167M8.33335 6.66667H6.66669M8.33335 6.66667V5M8.33335 6.66667H10.4167M10.4167 6.66667V5" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.33335 13.3333V10H12.0834C12.5254 10 12.9493 10.1756 13.2619 10.4882C13.5744 10.8007 13.75 11.2246 13.75 11.6667C13.75 12.1087 13.5744 12.5326 13.2619 12.8452C12.9493 13.1577 12.5254 13.3333 12.0834 13.3333H10.4167M8.33335 13.3333V15M8.33335 13.3333H6.66669H10.4167M8.33335 13.3333H10.4167M10.4167 13.3333V15" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
    </svg> },
    { value: '2', label: t('Currencies'), icon: 
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <circle cx="10" cy="9.99996" r="8.33333" stroke="#FCFCFC"/>
      <path d="M10 5V15" stroke="#FCFCFC" stroke-linecap="round"/>
      <path d="M12.5 7.91671C12.5 6.76611 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.76611 7.5 7.91671C7.5 9.0673 8.61929 10 10 10C11.3807 10 12.5 10.9328 12.5 12.0834C12.5 13.234 11.3807 14.1667 10 14.1667C8.61929 14.1667 7.5 13.234 7.5 12.0834" stroke="#FCFCFC" stroke-linecap="round"/>
    </svg> },
    { value: '3', label: t('CorporateShares'), icon: 
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M7.5 12.5L12.5 7.5" stroke="#FCFCFC" stroke-linecap="round"/>
      <path d="M12.9167 12.0833C12.9167 12.5436 12.5436 12.9167 12.0833 12.9167C11.6231 12.9167 11.25 12.5436 11.25 12.0833C11.25 11.6231 11.6231 11.25 12.0833 11.25C12.5436 11.25 12.9167 11.6231 12.9167 12.0833Z" fill="#FCFCFC"/>
      <path d="M8.74998 7.91671C8.74998 8.37694 8.37688 8.75004 7.91665 8.75004C7.45641 8.75004 7.08331 8.37694 7.08331 7.91671C7.08331 7.45647 7.45641 7.08337 7.91665 7.08337C8.37688 7.08337 8.74998 7.45647 8.74998 7.91671Z" fill="#FCFCFC"/>
      <path d="M1.66669 9.99996C1.66669 6.07159 1.66669 4.1074 2.88708 2.88701C4.10746 1.66663 6.07165 1.66663 10 1.66663C13.9284 1.66663 15.8926 1.66663 17.113 2.88701C18.3334 4.1074 18.3334 6.07159 18.3334 9.99996C18.3334 13.9283 18.3334 15.8925 17.113 17.1129C15.8926 18.3333 13.9284 18.3333 10 18.3333C6.07165 18.3333 4.10746 18.3333 2.88708 17.1129C1.66669 15.8925 1.66669 13.9283 1.66669 9.99996Z" stroke="#FCFCFC"/>
    </svg> },
    { value: '4', label: t('OilAndGas'), icon: 
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M13.3333 18.3333V6.66663C13.3333 4.3096 13.3333 3.13109 12.6011 2.39886C11.8689 1.66663 10.6904 1.66663 8.33333 1.66663H7.5C5.14298 1.66663 3.96447 1.66663 3.23223 2.39886C2.5 3.13109 2.5 4.3096 2.5 6.66663V18.3333" stroke="#FCFCFC"/>
      <path d="M9.16667 5H6.66667C5.88099 5 5.48816 5 5.24408 5.24408C5 5.48816 5 5.88099 5 6.66667C5 7.45234 5 7.84518 5.24408 8.08926C5.48816 8.33333 5.88099 8.33333 6.66667 8.33333H9.16667C9.95234 8.33333 10.3452 8.33333 10.5893 8.08926C10.8333 7.84518 10.8333 7.45234 10.8333 6.66667C10.8333 5.88099 10.8333 5.48816 10.5893 5.24408C10.3452 5 9.95234 5 9.16667 5Z" stroke="#FCFCFC"/>
      <path d="M5.83331 14.1666H9.99998" stroke="#FCFCFC" stroke-linecap="round"/>
      <path d="M14.1667 18.3334H1.66669" stroke="#FCFCFC" stroke-linecap="round"/>
      <path d="M16.25 3.33337L17.2776 4.15543C17.3924 4.24731 17.4498 4.29325 17.5021 4.34008C17.9948 4.78176 18.2923 5.40076 18.3294 6.06141C18.3333 6.13145 18.3333 6.20499 18.3333 6.35208V15.4167C18.3333 16.1071 17.7737 16.6667 17.0833 16.6667C16.393 16.6667 15.8333 16.1071 15.8333 15.4167V15.3572C15.8333 14.6997 15.3003 14.1667 14.6428 14.1667H13.3333" stroke="#FCFCFC" stroke-linecap="round"/>
      <path d="M18.3333 6.66663H17.0833C16.393 6.66663 15.8333 7.22627 15.8333 7.91663V9.93234C15.8333 10.4704 16.1776 10.9481 16.688 11.1182L18.3333 11.6666" stroke="#FCFCFC" stroke-linecap="round"/>
    </svg> },
    { value: '5', label: t('PreciousMetals'),icon:
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M1.875 6.875H18.125M1.3836 7.35195L9.49883 17.8777C9.55781 17.9546 9.63369 18.0169 9.72059 18.0598C9.8075 18.1026 9.9031 18.1249 10 18.1249C10.0969 18.1249 10.1925 18.1026 10.2794 18.0598C10.3663 18.0169 10.4422 17.9546 10.5012 17.8777L18.6164 7.35195C18.6962 7.24807 18.7425 7.12235 18.7491 6.99153C18.7557 6.8607 18.7224 6.73095 18.6535 6.61953L15.9008 2.17617C15.8441 2.08422 15.7649 2.00828 15.6706 1.95557C15.5763 1.90286 15.4701 1.87512 15.3621 1.875H4.63789C4.52988 1.87512 4.42369 1.90286 4.32941 1.95557C4.23513 2.00828 4.15589 2.08422 4.09922 2.17617L1.34649 6.61953C1.2776 6.73095 1.24426 6.8607 1.25089 6.99153C1.25752 7.12235 1.3038 7.24807 1.3836 7.35195Z" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.625 2.5L13.75 6.875M13.75 6.875L10 1.875L6.25 6.875M13.75 6.875L10 17.5L6.25 6.875M4.375 2.5L6.25 6.875" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
     },
  ])

  const assetTypeMap = {
    '1': 'crypto',
    '2': 'currency',
    '3': 'share',
    '4': 'oil_gas',
    '5': 'metal',
    '6': 'ruShare',

  };
  useEffect(() => {
    if (i18n.language == 'ru') {
      setSelectAssets({ value: '999', label: t('AllAssets'), icon: "" })
      if (favorites.length > 0 && !selectOptions.includes(el => el.value === '888')) {
        setSelectedOptions([
          { value: '999', label: t('AllAssets'), icon: '' },
          { value: '1', label: t('CryptoCurrencies'), icon: 
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M10 18.3333C14.6024 18.3333 18.3334 14.6023 18.3334 9.99996C18.3334 5.39759 14.6024 1.66663 10 1.66663C5.39765 1.66663 1.66669 5.39759 1.66669 9.99996C1.66669 14.6023 5.39765 18.3333 10 18.3333Z" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.33335 6.66667V10H11.25C11.692 10 12.116 9.8244 12.4285 9.51184C12.7411 9.19928 12.9167 8.77536 12.9167 8.33333C12.9167 7.89131 12.7411 7.46738 12.4285 7.15482C12.116 6.84226 11.692 6.66667 11.25 6.66667H10.4167M8.33335 6.66667H6.66669M8.33335 6.66667V5M8.33335 6.66667H10.4167M10.4167 6.66667V5" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.33335 13.3333V10H12.0834C12.5254 10 12.9493 10.1756 13.2619 10.4882C13.5744 10.8007 13.75 11.2246 13.75 11.6667C13.75 12.1087 13.5744 12.5326 13.2619 12.8452C12.9493 13.1577 12.5254 13.3333 12.0834 13.3333H10.4167M8.33335 13.3333V15M8.33335 13.3333H6.66669H10.4167M8.33335 13.3333H10.4167M10.4167 13.3333V15" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
          </svg> },
          { value: '2', label: t('Currencies'), icon: 
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <circle cx="10" cy="9.99996" r="8.33333" stroke="#FCFCFC"/>
            <path d="M10 5V15" stroke="#FCFCFC" stroke-linecap="round"/>
            <path d="M12.5 7.91671C12.5 6.76611 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.76611 7.5 7.91671C7.5 9.0673 8.61929 10 10 10C11.3807 10 12.5 10.9328 12.5 12.0834C12.5 13.234 11.3807 14.1667 10 14.1667C8.61929 14.1667 7.5 13.234 7.5 12.0834" stroke="#FCFCFC" stroke-linecap="round"/>
          </svg> },
          { value: '3', label: t('CorporateShares'), icon: 
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M7.5 12.5L12.5 7.5" stroke="#FCFCFC" stroke-linecap="round"/>
            <path d="M12.9167 12.0833C12.9167 12.5436 12.5436 12.9167 12.0833 12.9167C11.6231 12.9167 11.25 12.5436 11.25 12.0833C11.25 11.6231 11.6231 11.25 12.0833 11.25C12.5436 11.25 12.9167 11.6231 12.9167 12.0833Z" fill="#FCFCFC"/>
            <path d="M8.74998 7.91671C8.74998 8.37694 8.37688 8.75004 7.91665 8.75004C7.45641 8.75004 7.08331 8.37694 7.08331 7.91671C7.08331 7.45647 7.45641 7.08337 7.91665 7.08337C8.37688 7.08337 8.74998 7.45647 8.74998 7.91671Z" fill="#FCFCFC"/>
            <path d="M1.66669 9.99996C1.66669 6.07159 1.66669 4.1074 2.88708 2.88701C4.10746 1.66663 6.07165 1.66663 10 1.66663C13.9284 1.66663 15.8926 1.66663 17.113 2.88701C18.3334 4.1074 18.3334 6.07159 18.3334 9.99996C18.3334 13.9283 18.3334 15.8925 17.113 17.1129C15.8926 18.3333 13.9284 18.3333 10 18.3333C6.07165 18.3333 4.10746 18.3333 2.88708 17.1129C1.66669 15.8925 1.66669 13.9283 1.66669 9.99996Z" stroke="#FCFCFC"/>
          </svg> },
          { value: '4', label: t('OilAndGas'), icon: 
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M13.3333 18.3333V6.66663C13.3333 4.3096 13.3333 3.13109 12.6011 2.39886C11.8689 1.66663 10.6904 1.66663 8.33333 1.66663H7.5C5.14298 1.66663 3.96447 1.66663 3.23223 2.39886C2.5 3.13109 2.5 4.3096 2.5 6.66663V18.3333" stroke="#FCFCFC"/>
            <path d="M9.16667 5H6.66667C5.88099 5 5.48816 5 5.24408 5.24408C5 5.48816 5 5.88099 5 6.66667C5 7.45234 5 7.84518 5.24408 8.08926C5.48816 8.33333 5.88099 8.33333 6.66667 8.33333H9.16667C9.95234 8.33333 10.3452 8.33333 10.5893 8.08926C10.8333 7.84518 10.8333 7.45234 10.8333 6.66667C10.8333 5.88099 10.8333 5.48816 10.5893 5.24408C10.3452 5 9.95234 5 9.16667 5Z" stroke="#FCFCFC"/>
            <path d="M5.83331 14.1666H9.99998" stroke="#FCFCFC" stroke-linecap="round"/>
            <path d="M14.1667 18.3334H1.66669" stroke="#FCFCFC" stroke-linecap="round"/>
            <path d="M16.25 3.33337L17.2776 4.15543C17.3924 4.24731 17.4498 4.29325 17.5021 4.34008C17.9948 4.78176 18.2923 5.40076 18.3294 6.06141C18.3333 6.13145 18.3333 6.20499 18.3333 6.35208V15.4167C18.3333 16.1071 17.7737 16.6667 17.0833 16.6667C16.393 16.6667 15.8333 16.1071 15.8333 15.4167V15.3572C15.8333 14.6997 15.3003 14.1667 14.6428 14.1667H13.3333" stroke="#FCFCFC" stroke-linecap="round"/>
            <path d="M18.3333 6.66663H17.0833C16.393 6.66663 15.8333 7.22627 15.8333 7.91663V9.93234C15.8333 10.4704 16.1776 10.9481 16.688 11.1182L18.3333 11.6666" stroke="#FCFCFC" stroke-linecap="round"/>
          </svg> },
          { value: '5', label: t('PreciousMetals'),icon:
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M1.875 6.875H18.125M1.3836 7.35195L9.49883 17.8777C9.55781 17.9546 9.63369 18.0169 9.72059 18.0598C9.8075 18.1026 9.9031 18.1249 10 18.1249C10.0969 18.1249 10.1925 18.1026 10.2794 18.0598C10.3663 18.0169 10.4422 17.9546 10.5012 17.8777L18.6164 7.35195C18.6962 7.24807 18.7425 7.12235 18.7491 6.99153C18.7557 6.8607 18.7224 6.73095 18.6535 6.61953L15.9008 2.17617C15.8441 2.08422 15.7649 2.00828 15.6706 1.95557C15.5763 1.90286 15.4701 1.87512 15.3621 1.875H4.63789C4.52988 1.87512 4.42369 1.90286 4.32941 1.95557C4.23513 2.00828 4.15589 2.08422 4.09922 2.17617L1.34649 6.61953C1.2776 6.73095 1.24426 6.8607 1.25089 6.99153C1.25752 7.12235 1.3038 7.24807 1.3836 7.35195Z" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.625 2.5L13.75 6.875M13.75 6.875L10 1.875L6.25 6.875M13.75 6.875L10 17.5L6.25 6.875M4.375 2.5L6.25 6.875" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
           },
          { value: '888', label: t('Favorites') },
        ])
      } else {
        setSelectedOptions([
          { value: '999', label: t('AllAssets'), icon: "" },
          { value: '1', label: t('CryptoCurrencies'), icon: 
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M10 18.3333C14.6024 18.3333 18.3334 14.6023 18.3334 9.99996C18.3334 5.39759 14.6024 1.66663 10 1.66663C5.39765 1.66663 1.66669 5.39759 1.66669 9.99996C1.66669 14.6023 5.39765 18.3333 10 18.3333Z" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8.33335 6.66667V10H11.25C11.692 10 12.116 9.8244 12.4285 9.51184C12.7411 9.19928 12.9167 8.77536 12.9167 8.33333C12.9167 7.89131 12.7411 7.46738 12.4285 7.15482C12.116 6.84226 11.692 6.66667 11.25 6.66667H10.4167M8.33335 6.66667H6.66669M8.33335 6.66667V5M8.33335 6.66667H10.4167M10.4167 6.66667V5" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8.33335 13.3333V10H12.0834C12.5254 10 12.9493 10.1756 13.2619 10.4882C13.5744 10.8007 13.75 11.2246 13.75 11.6667C13.75 12.1087 13.5744 12.5326 13.2619 12.8452C12.9493 13.1577 12.5254 13.3333 12.0834 13.3333H10.4167M8.33335 13.3333V15M8.33335 13.3333H6.66669H10.4167M8.33335 13.3333H10.4167M10.4167 13.3333V15" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> },
          { value: '2', label: t('Currencies'), icon: 
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <circle cx="10" cy="9.99996" r="8.33333" stroke="#FCFCFC"/>
              <path d="M10 5V15" stroke="#FCFCFC" stroke-linecap="round"/>
              <path d="M12.5 7.91671C12.5 6.76611 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.76611 7.5 7.91671C7.5 9.0673 8.61929 10 10 10C11.3807 10 12.5 10.9328 12.5 12.0834C12.5 13.234 11.3807 14.1667 10 14.1667C8.61929 14.1667 7.5 13.234 7.5 12.0834" stroke="#FCFCFC" stroke-linecap="round"/>
            </svg> },
          { value: '3', label: t('CorporateShares'), icon: 
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M7.5 12.5L12.5 7.5" stroke="#FCFCFC" stroke-linecap="round"/>
              <path d="M12.9167 12.0833C12.9167 12.5436 12.5436 12.9167 12.0833 12.9167C11.6231 12.9167 11.25 12.5436 11.25 12.0833C11.25 11.6231 11.6231 11.25 12.0833 11.25C12.5436 11.25 12.9167 11.6231 12.9167 12.0833Z" fill="#FCFCFC"/>
              <path d="M8.74998 7.91671C8.74998 8.37694 8.37688 8.75004 7.91665 8.75004C7.45641 8.75004 7.08331 8.37694 7.08331 7.91671C7.08331 7.45647 7.45641 7.08337 7.91665 7.08337C8.37688 7.08337 8.74998 7.45647 8.74998 7.91671Z" fill="#FCFCFC"/>
              <path d="M1.66669 9.99996C1.66669 6.07159 1.66669 4.1074 2.88708 2.88701C4.10746 1.66663 6.07165 1.66663 10 1.66663C13.9284 1.66663 15.8926 1.66663 17.113 2.88701C18.3334 4.1074 18.3334 6.07159 18.3334 9.99996C18.3334 13.9283 18.3334 15.8925 17.113 17.1129C15.8926 18.3333 13.9284 18.3333 10 18.3333C6.07165 18.3333 4.10746 18.3333 2.88708 17.1129C1.66669 15.8925 1.66669 13.9283 1.66669 9.99996Z" stroke="#FCFCFC"/>
            </svg> },
          { value: '4', label: t('OilAndGas'), icon: 
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M13.3333 18.3333V6.66663C13.3333 4.3096 13.3333 3.13109 12.6011 2.39886C11.8689 1.66663 10.6904 1.66663 8.33333 1.66663H7.5C5.14298 1.66663 3.96447 1.66663 3.23223 2.39886C2.5 3.13109 2.5 4.3096 2.5 6.66663V18.3333" stroke="#FCFCFC"/>
              <path d="M9.16667 5H6.66667C5.88099 5 5.48816 5 5.24408 5.24408C5 5.48816 5 5.88099 5 6.66667C5 7.45234 5 7.84518 5.24408 8.08926C5.48816 8.33333 5.88099 8.33333 6.66667 8.33333H9.16667C9.95234 8.33333 10.3452 8.33333 10.5893 8.08926C10.8333 7.84518 10.8333 7.45234 10.8333 6.66667C10.8333 5.88099 10.8333 5.48816 10.5893 5.24408C10.3452 5 9.95234 5 9.16667 5Z" stroke="#FCFCFC"/>
              <path d="M5.83331 14.1666H9.99998" stroke="#FCFCFC" stroke-linecap="round"/>
              <path d="M14.1667 18.3334H1.66669" stroke="#FCFCFC" stroke-linecap="round"/>
              <path d="M16.25 3.33337L17.2776 4.15543C17.3924 4.24731 17.4498 4.29325 17.5021 4.34008C17.9948 4.78176 18.2923 5.40076 18.3294 6.06141C18.3333 6.13145 18.3333 6.20499 18.3333 6.35208V15.4167C18.3333 16.1071 17.7737 16.6667 17.0833 16.6667C16.393 16.6667 15.8333 16.1071 15.8333 15.4167V15.3572C15.8333 14.6997 15.3003 14.1667 14.6428 14.1667H13.3333" stroke="#FCFCFC" stroke-linecap="round"/>
              <path d="M18.3333 6.66663H17.0833C16.393 6.66663 15.8333 7.22627 15.8333 7.91663V9.93234C15.8333 10.4704 16.1776 10.9481 16.688 11.1182L18.3333 11.6666" stroke="#FCFCFC" stroke-linecap="round"/>
            </svg>},
          { value: '5', label: t('PreciousMetals'),icon:
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M1.875 6.875H18.125M1.3836 7.35195L9.49883 17.8777C9.55781 17.9546 9.63369 18.0169 9.72059 18.0598C9.8075 18.1026 9.9031 18.1249 10 18.1249C10.0969 18.1249 10.1925 18.1026 10.2794 18.0598C10.3663 18.0169 10.4422 17.9546 10.5012 17.8777L18.6164 7.35195C18.6962 7.24807 18.7425 7.12235 18.7491 6.99153C18.7557 6.8607 18.7224 6.73095 18.6535 6.61953L15.9008 2.17617C15.8441 2.08422 15.7649 2.00828 15.6706 1.95557C15.5763 1.90286 15.4701 1.87512 15.3621 1.875H4.63789C4.52988 1.87512 4.42369 1.90286 4.32941 1.95557C4.23513 2.00828 4.15589 2.08422 4.09922 2.17617L1.34649 6.61953C1.2776 6.73095 1.24426 6.8607 1.25089 6.99153C1.25752 7.12235 1.3038 7.24807 1.3836 7.35195Z" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.625 2.5L13.75 6.875M13.75 6.875L10 1.875L6.25 6.875M13.75 6.875L10 17.5L6.25 6.875M4.375 2.5L6.25 6.875" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>},
        ])
      }
    } else {
      setSelectAssets({ value: '999', label: t('AllAssets'), icon: ""})
      if (favorites.length > 0 && !selectOptions.includes(el => el.value === '888')) {
        setSelectedOptions([
          { value: '999', label: t('AllAssets'), icon: "" },
          { value: '1', label: t('CryptoCurrencies'), icon: 
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M10 18.3333C14.6024 18.3333 18.3334 14.6023 18.3334 9.99996C18.3334 5.39759 14.6024 1.66663 10 1.66663C5.39765 1.66663 1.66669 5.39759 1.66669 9.99996C1.66669 14.6023 5.39765 18.3333 10 18.3333Z" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8.33335 6.66667V10H11.25C11.692 10 12.116 9.8244 12.4285 9.51184C12.7411 9.19928 12.9167 8.77536 12.9167 8.33333C12.9167 7.89131 12.7411 7.46738 12.4285 7.15482C12.116 6.84226 11.692 6.66667 11.25 6.66667H10.4167M8.33335 6.66667H6.66669M8.33335 6.66667V5M8.33335 6.66667H10.4167M10.4167 6.66667V5" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8.33335 13.3333V10H12.0834C12.5254 10 12.9493 10.1756 13.2619 10.4882C13.5744 10.8007 13.75 11.2246 13.75 11.6667C13.75 12.1087 13.5744 12.5326 13.2619 12.8452C12.9493 13.1577 12.5254 13.3333 12.0834 13.3333H10.4167M8.33335 13.3333V15M8.33335 13.3333H6.66669H10.4167M8.33335 13.3333H10.4167M10.4167 13.3333V15" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> },
          { value: '2', label: t('Currencies'), icon: 
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <circle cx="10" cy="9.99996" r="8.33333" stroke="#FCFCFC"/>
              <path d="M10 5V15" stroke="#FCFCFC" stroke-linecap="round"/>
              <path d="M12.5 7.91671C12.5 6.76611 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.76611 7.5 7.91671C7.5 9.0673 8.61929 10 10 10C11.3807 10 12.5 10.9328 12.5 12.0834C12.5 13.234 11.3807 14.1667 10 14.1667C8.61929 14.1667 7.5 13.234 7.5 12.0834" stroke="#FCFCFC" stroke-linecap="round"/>
            </svg>},
          { value: '3', label: t('CorporateShares'), icon: 
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M7.5 12.5L12.5 7.5" stroke="#FCFCFC" stroke-linecap="round"/>
              <path d="M12.9167 12.0833C12.9167 12.5436 12.5436 12.9167 12.0833 12.9167C11.6231 12.9167 11.25 12.5436 11.25 12.0833C11.25 11.6231 11.6231 11.25 12.0833 11.25C12.5436 11.25 12.9167 11.6231 12.9167 12.0833Z" fill="#FCFCFC"/>
              <path d="M8.74998 7.91671C8.74998 8.37694 8.37688 8.75004 7.91665 8.75004C7.45641 8.75004 7.08331 8.37694 7.08331 7.91671C7.08331 7.45647 7.45641 7.08337 7.91665 7.08337C8.37688 7.08337 8.74998 7.45647 8.74998 7.91671Z" fill="#FCFCFC"/>
              <path d="M1.66669 9.99996C1.66669 6.07159 1.66669 4.1074 2.88708 2.88701C4.10746 1.66663 6.07165 1.66663 10 1.66663C13.9284 1.66663 15.8926 1.66663 17.113 2.88701C18.3334 4.1074 18.3334 6.07159 18.3334 9.99996C18.3334 13.9283 18.3334 15.8925 17.113 17.1129C15.8926 18.3333 13.9284 18.3333 10 18.3333C6.07165 18.3333 4.10746 18.3333 2.88708 17.1129C1.66669 15.8925 1.66669 13.9283 1.66669 9.99996Z" stroke="#FCFCFC"/>
            </svg> },
          { value: '4', label: t('OilAndGas'), icon: 
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M13.3333 18.3333V6.66663C13.3333 4.3096 13.3333 3.13109 12.6011 2.39886C11.8689 1.66663 10.6904 1.66663 8.33333 1.66663H7.5C5.14298 1.66663 3.96447 1.66663 3.23223 2.39886C2.5 3.13109 2.5 4.3096 2.5 6.66663V18.3333" stroke="#FCFCFC"/>
              <path d="M9.16667 5H6.66667C5.88099 5 5.48816 5 5.24408 5.24408C5 5.48816 5 5.88099 5 6.66667C5 7.45234 5 7.84518 5.24408 8.08926C5.48816 8.33333 5.88099 8.33333 6.66667 8.33333H9.16667C9.95234 8.33333 10.3452 8.33333 10.5893 8.08926C10.8333 7.84518 10.8333 7.45234 10.8333 6.66667C10.8333 5.88099 10.8333 5.48816 10.5893 5.24408C10.3452 5 9.95234 5 9.16667 5Z" stroke="#FCFCFC"/>
              <path d="M5.83331 14.1666H9.99998" stroke="#FCFCFC" stroke-linecap="round"/>
              <path d="M14.1667 18.3334H1.66669" stroke="#FCFCFC" stroke-linecap="round"/>
              <path d="M16.25 3.33337L17.2776 4.15543C17.3924 4.24731 17.4498 4.29325 17.5021 4.34008C17.9948 4.78176 18.2923 5.40076 18.3294 6.06141C18.3333 6.13145 18.3333 6.20499 18.3333 6.35208V15.4167C18.3333 16.1071 17.7737 16.6667 17.0833 16.6667C16.393 16.6667 15.8333 16.1071 15.8333 15.4167V15.3572C15.8333 14.6997 15.3003 14.1667 14.6428 14.1667H13.3333" stroke="#FCFCFC" stroke-linecap="round"/>
              <path d="M18.3333 6.66663H17.0833C16.393 6.66663 15.8333 7.22627 15.8333 7.91663V9.93234C15.8333 10.4704 16.1776 10.9481 16.688 11.1182L18.3333 11.6666" stroke="#FCFCFC" stroke-linecap="round"/>
            </svg> },
          { value: '5', label: t('PreciousMetals') ,icon:
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M1.875 6.875H18.125M1.3836 7.35195L9.49883 17.8777C9.55781 17.9546 9.63369 18.0169 9.72059 18.0598C9.8075 18.1026 9.9031 18.1249 10 18.1249C10.0969 18.1249 10.1925 18.1026 10.2794 18.0598C10.3663 18.0169 10.4422 17.9546 10.5012 17.8777L18.6164 7.35195C18.6962 7.24807 18.7425 7.12235 18.7491 6.99153C18.7557 6.8607 18.7224 6.73095 18.6535 6.61953L15.9008 2.17617C15.8441 2.08422 15.7649 2.00828 15.6706 1.95557C15.5763 1.90286 15.4701 1.87512 15.3621 1.875H4.63789C4.52988 1.87512 4.42369 1.90286 4.32941 1.95557C4.23513 2.00828 4.15589 2.08422 4.09922 2.17617L1.34649 6.61953C1.2776 6.73095 1.24426 6.8607 1.25089 6.99153C1.25752 7.12235 1.3038 7.24807 1.3836 7.35195Z" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.625 2.5L13.75 6.875M13.75 6.875L10 1.875L6.25 6.875M13.75 6.875L10 17.5L6.25 6.875M4.375 2.5L6.25 6.875" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>},
          { value: '888', label: t('Favorites') },
        ])
      } else {
        setSelectedOptions([
          { value: '999', label: t('AllAssets'), icon: "" },
          { value: '1', label: t('CryptoCurrencies'), icon: 
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M10 18.3333C14.6024 18.3333 18.3334 14.6023 18.3334 9.99996C18.3334 5.39759 14.6024 1.66663 10 1.66663C5.39765 1.66663 1.66669 5.39759 1.66669 9.99996C1.66669 14.6023 5.39765 18.3333 10 18.3333Z" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8.33335 6.66667V10H11.25C11.692 10 12.116 9.8244 12.4285 9.51184C12.7411 9.19928 12.9167 8.77536 12.9167 8.33333C12.9167 7.89131 12.7411 7.46738 12.4285 7.15482C12.116 6.84226 11.692 6.66667 11.25 6.66667H10.4167M8.33335 6.66667H6.66669M8.33335 6.66667V5M8.33335 6.66667H10.4167M10.4167 6.66667V5" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8.33335 13.3333V10H12.0834C12.5254 10 12.9493 10.1756 13.2619 10.4882C13.5744 10.8007 13.75 11.2246 13.75 11.6667C13.75 12.1087 13.5744 12.5326 13.2619 12.8452C12.9493 13.1577 12.5254 13.3333 12.0834 13.3333H10.4167M8.33335 13.3333V15M8.33335 13.3333H6.66669H10.4167M8.33335 13.3333H10.4167M10.4167 13.3333V15" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> },
          { value: '2', label: t('Currencies'), icon: 
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <circle cx="10" cy="9.99996" r="8.33333" stroke="#FCFCFC"/>
              <path d="M10 5V15" stroke="#FCFCFC" stroke-linecap="round"/>
              <path d="M12.5 7.91671C12.5 6.76611 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.76611 7.5 7.91671C7.5 9.0673 8.61929 10 10 10C11.3807 10 12.5 10.9328 12.5 12.0834C12.5 13.234 11.3807 14.1667 10 14.1667C8.61929 14.1667 7.5 13.234 7.5 12.0834" stroke="#FCFCFC" stroke-linecap="round"/>
            </svg>},
          { value: '3', label: t('CorporateShares'), icon: 
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M7.5 12.5L12.5 7.5" stroke="#FCFCFC" stroke-linecap="round"/>
              <path d="M12.9167 12.0833C12.9167 12.5436 12.5436 12.9167 12.0833 12.9167C11.6231 12.9167 11.25 12.5436 11.25 12.0833C11.25 11.6231 11.6231 11.25 12.0833 11.25C12.5436 11.25 12.9167 11.6231 12.9167 12.0833Z" fill="#FCFCFC"/>
              <path d="M8.74998 7.91671C8.74998 8.37694 8.37688 8.75004 7.91665 8.75004C7.45641 8.75004 7.08331 8.37694 7.08331 7.91671C7.08331 7.45647 7.45641 7.08337 7.91665 7.08337C8.37688 7.08337 8.74998 7.45647 8.74998 7.91671Z" fill="#FCFCFC"/>
              <path d="M1.66669 9.99996C1.66669 6.07159 1.66669 4.1074 2.88708 2.88701C4.10746 1.66663 6.07165 1.66663 10 1.66663C13.9284 1.66663 15.8926 1.66663 17.113 2.88701C18.3334 4.1074 18.3334 6.07159 18.3334 9.99996C18.3334 13.9283 18.3334 15.8925 17.113 17.1129C15.8926 18.3333 13.9284 18.3333 10 18.3333C6.07165 18.3333 4.10746 18.3333 2.88708 17.1129C1.66669 15.8925 1.66669 13.9283 1.66669 9.99996Z" stroke="#FCFCFC"/>
            </svg> },
          { value: '4', label: t('OilAndGas'), icon: 
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M13.3333 18.3333V6.66663C13.3333 4.3096 13.3333 3.13109 12.6011 2.39886C11.8689 1.66663 10.6904 1.66663 8.33333 1.66663H7.5C5.14298 1.66663 3.96447 1.66663 3.23223 2.39886C2.5 3.13109 2.5 4.3096 2.5 6.66663V18.3333" stroke="#FCFCFC"/>
              <path d="M9.16667 5H6.66667C5.88099 5 5.48816 5 5.24408 5.24408C5 5.48816 5 5.88099 5 6.66667C5 7.45234 5 7.84518 5.24408 8.08926C5.48816 8.33333 5.88099 8.33333 6.66667 8.33333H9.16667C9.95234 8.33333 10.3452 8.33333 10.5893 8.08926C10.8333 7.84518 10.8333 7.45234 10.8333 6.66667C10.8333 5.88099 10.8333 5.48816 10.5893 5.24408C10.3452 5 9.95234 5 9.16667 5Z" stroke="#FCFCFC"/>
              <path d="M5.83331 14.1666H9.99998" stroke="#FCFCFC" stroke-linecap="round"/>
              <path d="M14.1667 18.3334H1.66669" stroke="#FCFCFC" stroke-linecap="round"/>
              <path d="M16.25 3.33337L17.2776 4.15543C17.3924 4.24731 17.4498 4.29325 17.5021 4.34008C17.9948 4.78176 18.2923 5.40076 18.3294 6.06141C18.3333 6.13145 18.3333 6.20499 18.3333 6.35208V15.4167C18.3333 16.1071 17.7737 16.6667 17.0833 16.6667C16.393 16.6667 15.8333 16.1071 15.8333 15.4167V15.3572C15.8333 14.6997 15.3003 14.1667 14.6428 14.1667H13.3333" stroke="#FCFCFC" stroke-linecap="round"/>
              <path d="M18.3333 6.66663H17.0833C16.393 6.66663 15.8333 7.22627 15.8333 7.91663V9.93234C15.8333 10.4704 16.1776 10.9481 16.688 11.1182L18.3333 11.6666" stroke="#FCFCFC" stroke-linecap="round"/>
            </svg> },
          { value: '5', label: t('PreciousMetals') ,icon:
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M1.875 6.875H18.125M1.3836 7.35195L9.49883 17.8777C9.55781 17.9546 9.63369 18.0169 9.72059 18.0598C9.8075 18.1026 9.9031 18.1249 10 18.1249C10.0969 18.1249 10.1925 18.1026 10.2794 18.0598C10.3663 18.0169 10.4422 17.9546 10.5012 17.8777L18.6164 7.35195C18.6962 7.24807 18.7425 7.12235 18.7491 6.99153C18.7557 6.8607 18.7224 6.73095 18.6535 6.61953L15.9008 2.17617C15.8441 2.08422 15.7649 2.00828 15.6706 1.95557C15.5763 1.90286 15.4701 1.87512 15.3621 1.875H4.63789C4.52988 1.87512 4.42369 1.90286 4.32941 1.95557C4.23513 2.00828 4.15589 2.08422 4.09922 2.17617L1.34649 6.61953C1.2776 6.73095 1.24426 6.8607 1.25089 6.99153C1.25752 7.12235 1.3038 7.24807 1.3836 7.35195Z" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.625 2.5L13.75 6.875M13.75 6.875L10 1.875L6.25 6.875M13.75 6.875L10 17.5L6.25 6.875M4.375 2.5L6.25 6.875" stroke="#FCFCFC" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>},
        ])
      }
    }
  }, [i18n.language])

  useEffect(() => {
    if (favorites?.length > 0) {
      if (!selectOptions.find(el => el.value === '888')) {
        setSelectedOptions(prev => [...prev, { value: '888', label: t('Favorites') }])
      }
    } else {
      setSelectedOptions(prev => [...prev.filter(el => el.value !== '888')])
    }
  }, [favorites])

  const filterAssets = (records, search, selectedAssetType) => {
    return records.filter(record => {
      const isFavorite = favorites?.includes(record.Id);
      const type = assetTypeMap[selectedAssetType.value] || '';
      const matchesType = selectedAssetType.value === '888' ? isFavorite : (selectedAssetType.value === '999' || record.Type === type);
      const matchesSearch = !search || record.Title.toLowerCase().includes(search.toLowerCase()) || record.Subtitle.toLowerCase().includes(search.toLowerCase());
      return matchesType && matchesSearch;
    });
  };

  useEffect(() => {
    const filteredAssets = filterAssets(currentRecords, search, selectAssets);
    setDisplayedRecords(filteredAssets);
  }, [currentRecords, search, selectAssets, favorites]);

  useEffect(() => {
    if (!activeAsset) setActiveAsset(currentRecords.find(el => el.Title === 'Bitcoin'))
  }, [currentRecords]);

  useEffect(() => {
    if (favorites?.length === 0) {
      setSelectAssets({ value: '999', label: t('AllAssets') })
    }
  }, [favorites])

  const CustomOption = (props) => {
    const { innerProps, innerRef } = props;
    return (
      <div ref={innerRef} {...innerProps} className={props.isSelected ? styles.custom_option_selected : styles.custom_option}>
        <div className={styles.option} style={{ color: props.data.color, backgroundColor: props.data.background, width: 'auto',gap:'0.8vh' }}>
          {props.data.icon}
          {props.data.label}
        </div>
      </div>)
  }


  const DropdownIndicator = ({ children, ...props }) => (
    <components.DropdownIndicator {...props}>
      <div className={styles.single_value_container}>
        <div className={styles.custom_icon}>
          <svg width={"1.7vh"} height={"1.6vh"} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5003 6L8.83366 10L4.16699 6" stroke="#A0A0AB" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        {children}
      </div>
    </components.DropdownIndicator>
  );
  return (
    <div className={styles.assets_list}>
      <div className={styles.select_search}>
        <div className={styles.select}>
          <Select
            closeMenuOnSelect={true}
            defaultValue={{ value: '999', label: t('AllAssets') }}
            value={selectAssets}
            styles={colourStylesCurrency(theme)}
            options={selectOptions}
            onChange={(e) =>
              setSelectAssets({ value: e.value, label: e.label })
            }
            components={{ DropdownIndicator, IndicatorSeparator: () => null, Option: CustomOption }}
          />
        </div>
        <div className={styles.search} onClick={handleSearchClick}>
          <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
            <g clipPath="url(#clip0_436_4317)">
              <circle cx="7.66634" cy="7.66683" r="6.33333" stroke="#51525C" />
              <path d="M13.333 13.3335L14.6663 14.6668" stroke="#51525C" strokeLinecap="round" />
            </g>
            <defs>
              <clipPath id="clip0_436_4317">
                <rect width={16} height={16} fill="white" />
              </clipPath>
            </defs>
          </svg>
          <input ref={inputRef} placeholder={t('Search')} onChange={e => setSearch(e.target.value)} />
        </div>
      </div>
      <div className={styles.assets}>
        <div className={styles.assets_title}>
          <span className={styles.name}>{t('Asset')}</span>
          <span className={styles.price}>{t('Price')}</span>
          <span className={styles.percent}>{t('Change 24H')}</span>
        </div>
        <div className={styles.assets_body}>
          {displayedRecords.map(el =>
            <div className={styles.asset} key={el.Id} onClick={() => {
              if (el.MarketClosed && el.MarketClosed) {
                handleClick()
                setSnackMessage(t('MarketClosed'))
                setSnackType('error')
                return
              } else {
                setActiveAsset(el)
              }
            }}>
              <span className={styles.title_icon}>
                <img src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${el.Subtitle.slice(1).slice(0, -1).toLowerCase()}.svg`} alt='icon'
                  onError={handleImgError} />
                  <span>
                    {el.Subtitle.slice(1).slice(0, -1) === 'USDC'?el.Subtitle.slice(1).slice(0, -1):el.Subtitle.slice(1).slice(0, -1).replace('USDT','').replace('USD','')}
                    <p>
                      {el.Type === 'crypto'?'USDT':"USD"}
                    </p>
                  </span>
              </span>
              {el.MarketClosed && el.MarketClosed === 1 ? <span className={styles.price} style={{display:'flex',alignItems:'center',gap:'0.8vh'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M3.49967 6.70324V5.3335C3.49967 2.84821 5.51439 0.833496 7.99967 0.833496C10.485 0.833496 12.4997 2.84821 12.4997 5.3335V6.70324C13.2428 6.75874 13.7268 6.89886 14.0806 7.25262C14.6663 7.8384 14.6663 8.78121 14.6663 10.6668C14.6663 12.5524 14.6663 13.4953 14.0806 14.081C13.4948 14.6668 12.552 14.6668 10.6663 14.6668H5.33301C3.44739 14.6668 2.50458 14.6668 1.91879 14.081C1.33301 13.4953 1.33301 12.5524 1.33301 10.6668C1.33301 8.78121 1.33301 7.8384 1.91879 7.25262C2.27255 6.89886 2.7565 6.75874 3.49967 6.70324ZM4.49967 5.3335C4.49967 3.4005 6.06668 1.8335 7.99967 1.8335C9.93267 1.8335 11.4997 3.4005 11.4997 5.3335V6.66921C11.2443 6.66683 10.9674 6.66683 10.6663 6.66683H5.33301C5.032 6.66683 4.75502 6.66683 4.49967 6.66921V5.3335Z" fill="#F63D68" />
                </svg>
                <span style={{ opacity: "0.4" }}>{t('Closed')}</span></span> : <span className={styles.price}>{account?.AccountCurrency == "EUR" ? "€" : "$"}{parseFloat((el.Price * (account?.AccountCurrency === "EUR" ? eurToUsd : 1))).toFixed(5)}</span>}
              <span className={styles.percent_icon}>
                <span className={`${styles.percent} ${el.Tendency.includes('-') ? '' : styles.plus}`} style={{ opacity: el.MarketClosed && el.MarketClosed === 1 ? '0.3' : "1" }}>
                  {el.Tendency}
                </span>
                {
                  favorites?.includes(el.Id) ?
                    <svg onClick={() => toggleFavorite(el.Id)} xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
                      <path d="M6.10178 3.60575C6.94622 2.09091 7.36843 1.3335 7.99967 1.3335C8.63092 1.3335 9.05313 2.09091 9.89757 3.60575L10.116 3.99765C10.356 4.42812 10.476 4.64335 10.663 4.78537C10.8501 4.92738 11.0831 4.9801 11.5491 5.08553L11.9733 5.18152C13.6131 5.55253 14.433 5.73804 14.6281 6.36532C14.8231 6.99259 14.2642 7.64621 13.1463 8.95345L12.8571 9.29164C12.5394 9.66312 12.3806 9.84886 12.3091 10.0786C12.2376 10.3084 12.2617 10.5562 12.3097 11.0519L12.3534 11.5031C12.5224 13.2472 12.6069 14.1193 12.0962 14.507C11.5856 14.8947 10.8179 14.5412 9.28256 13.8343L8.88535 13.6514C8.44906 13.4505 8.23091 13.3501 7.99967 13.3501C7.76844 13.3501 7.55029 13.4505 7.114 13.6514L6.71679 13.8343C5.18146 14.5412 4.41379 14.8947 3.90311 14.507C3.39242 14.1193 3.47693 13.2472 3.64594 11.5031L3.68966 11.0519C3.73769 10.5562 3.76171 10.3084 3.69025 10.0786C3.61879 9.84886 3.45996 9.66312 3.14229 9.29164L2.85307 8.95345C1.73517 7.64621 1.17622 6.99259 1.37129 6.36532C1.56635 5.73804 2.38625 5.55253 4.02603 5.18152L4.45027 5.08553C4.91624 4.9801 5.14923 4.92738 5.3363 4.78537C5.52338 4.64335 5.64336 4.42812 5.88332 3.99765L6.10178 3.60575Z" fill="#E9B90E" />
                    </svg>
                    :
                    <svg onClick={() => toggleFavorite(el.Id)} xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
                      <g clipPath="url(#clip0_436_6882)">
                        <path d="M6.10178 3.60575C6.94622 2.09091 7.36843 1.3335 7.99967 1.3335C8.63092 1.3335 9.05313 2.09091 9.89757 3.60575L10.116 3.99765C10.356 4.42812 10.476 4.64335 10.663 4.78537C10.8501 4.92738 11.0831 4.9801 11.5491 5.08553L11.9733 5.18152C13.6131 5.55253 14.433 5.73804 14.6281 6.36532C14.8231 6.99259 14.2642 7.64621 13.1463 8.95345L12.8571 9.29164C12.5394 9.66312 12.3806 9.84886 12.3091 10.0786C12.2376 10.3084 12.2617 10.5562 12.3097 11.0519L12.3534 11.5031C12.5224 13.2472 12.6069 14.1193 12.0962 14.507C11.5856 14.8947 10.8179 14.5412 9.28256 13.8343L8.88535 13.6514C8.44906 13.4505 8.23091 13.3501 7.99967 13.3501C7.76844 13.3501 7.55029 13.4505 7.114 13.6514L6.71679 13.8343C5.18146 14.5412 4.41379 14.8947 3.90311 14.507C3.39242 14.1193 3.47693 13.2472 3.64594 11.5031L3.68966 11.0519C3.73769 10.5562 3.76171 10.3084 3.69025 10.0786C3.61879 9.84886 3.45996 9.66312 3.14229 9.29164L2.85307 8.95345C1.73517 7.64621 1.17622 6.99259 1.37129 6.36532C1.56635 5.73804 2.38625 5.55253 4.02603 5.18152L4.45027 5.08553C4.91624 4.9801 5.14923 4.92738 5.3363 4.78537C5.52338 4.64335 5.64336 4.42812 5.88332 3.99765L6.10178 3.60575Z" stroke="#51525C" />
                      </g>
                      <defs>
                        <clipPath id="clip0_436_6882">
                          <rect width={16} height={16} rx={5} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                }
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
